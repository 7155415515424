var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('h3',{staticClass:"page-title"},[_vm._v("一番賞訂單管理")]),_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"4","md":"3"}},[_c('v-select',{attrs:{"label":"狀態","outlined":"","dense":"","clearable":"","hide-details":"","items":[
          { text: '全部', value: '' },
          { text: '待處理', value: 'pending' },
          { text: '處理中', value: 'progress' },
          { text: '已完成', value: 'finished' },
          ,
        ]},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"4","md":"3"}},[_c('v-text-field',{attrs:{"label":"關鍵字(會員資料、獎項)","outlined":"","dense":"","clearable":"","hide-details":""},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filterData,"mobile-breakpoint":0,"item-key":"index","footer-props":{
          itemsPerPageText: '每頁幾筆',
          itemsPerPageOptions: [30, 100, -1],
        }},scopedSlots:_vm._u([{key:"item.isAvailable",fn:function({ item }){return [_c('v-checkbox',{staticClass:"empty-label",on:{"change":function($event){return _vm.update(item, 'isAvailable')}},model:{value:(item.isAvailable),callback:function ($$v) {_vm.$set(item, "isAvailable", $$v)},expression:"item.isAvailable"}})]}},{key:"item.rewards",fn:function({ item }){return _vm._l((item.rewards),function(reward,index){return _c('p',{key:index,staticClass:"my-0"},[_vm._v(" "+_vm._s(index + 1)+". "+_vm._s(reward.reward)+" ")])})}},{key:"item.status",fn:function({ item }){return [_c('v-select',{attrs:{"text":"","color":"primary","outlined":"","dense":"","hide-details":"","items":[
              { text: '待處理', value: 'pending' },
              { text: '處理中', value: 'progress' },
              { text: '寄送中', value: 'sending' },
              { text: '已完成', value: 'finished' },
              ,
            ]},on:{"change":function($event){return _vm.update(item, 'status')}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }