<template>
  <v-container fluid>
    <h3 class="page-title">一番賞訂單管理</h3>
    <!-- <v-row>
      <v-col cols="auto" class="ml-auto">
        <v-btn color="primary" dense hide-details @click="addCategory">
          新增
        </v-btn>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col cols="6" sm="4" md="3">
        <v-select
          v-model="status"
          label="狀態"
          outlined
          dense
          clearable
          hide-details
          :items="[
            { text: '全部', value: '' },
            { text: '待處理', value: 'pending' },
            { text: '處理中', value: 'progress' },
            { text: '已完成', value: 'finished' },
            ,
          ]"
        ></v-select>
      </v-col>
      <v-col cols="6" sm="4" md="3">
        <v-text-field
          v-model="keyword"
          label="關鍵字(會員資料、獎項)"
          outlined
          dense
          clearable
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="filterData"
          :mobile-breakpoint="0"
          item-key="index"
          :footer-props="{
            itemsPerPageText: '每頁幾筆',
            itemsPerPageOptions: [30, 100, -1],
          }"
        >
          <template v-slot:item.isAvailable="{ item }">
            <v-checkbox
              class="empty-label"
              v-model="item.isAvailable"
              @change="update(item, 'isAvailable')"
            ></v-checkbox>
          </template>
          <template v-slot:item.rewards="{ item }">
            <p
              v-for="(reward, index) in item.rewards"
              :key="index"
              class="my-0"
            >
              {{ index + 1 }}. {{ reward.reward }}
            </p>
          </template>
          <template v-slot:item.status="{ item }">
            <v-select
              v-model="item.status"
              text
              color="primary"
              outlined
              dense
              hide-details
              :items="[
                { text: '待處理', value: 'pending' },
                { text: '處理中', value: 'progress' },
                { text: '寄送中', value: 'sending' },
                { text: '已完成', value: 'finished' },
                ,
              ]"
              @change="update(item, 'status')"
            ></v-select>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- <DialogIchiban
      v-if="dialog.ichiban"
      v-model="dialog.ichiban"
      :item="selectedItem"
      @load="load"
      :branches="accessBranch"
    ></DialogIchiban> -->
  </v-container>
</template>

<script>
import dayjs from "dayjs";
import _ from "lodash";
import util from "@/mixins/util.vue";

export default {
  name: "Ichiban",
  mixins: [util],
  components: {},
  data: () => ({
    data: [],
    ichibans: [],
    branch: null,
    isOnline: null,
    isAvailable: true,
    dialog: {
      open: false,
      type: "update",
    },
    selectedItem: null,
    keyword: "",
    status: "",
    headers: [
      {
        text: "會員ID",
        value: "user.userID",
        align: "left",
        sortable: false,
      },
      {
        text: "名字",
        value: "name",
        align: "left",
        sortable: false,
      },
      {
        text: "電話",
        value: "phone",
        align: "left",
        sortable: false,
      },
      {
        text: "收件地址",
        value: "address",
        align: "left",
        sortable: false,
        width: 150,
      },
      {
        text: "獎項",
        value: "rewards",
        align: "left",
        sortable: false,
        width: 150,
      },
      {
        text: "中獎時間",
        value: "createdAt",
        align: "center",
        sortable: true,
      },
      {
        text: "店別",
        value: "branch",
        align: "center",
        sortable: false,
      },
      {
        text: "狀態",
        value: "status",
        align: "center",
        sortable: false,
        width: 150,
      },
    ],
  }),
  computed: {
    filterData() {
      let data = this.data;
      if (this.status) {
        data = data.filter((item) => item.status === this.status);
      }
      if (this.keyword) {
        data = data.filter((item) => {
          console.log(this.keyword);
          return (
            item.phone.includes(this.keyword) ||
            item.name.includes(this.keyword) ||
            item.address.includes(this.keyword) ||
            item.user.userID.includes(this.keyword) ||
            item.rewards.some(({ reward }) => reward?.includes(this.keyword))
          );
        });
      }
      return data;
    },
  },
  created() {
    // this.$nextTick(() => {
    //   this.ready = true;
    // this.getIchiban();
    this.load();
    // });
  },
  methods: {
    async addCategory() {
      this.selectedItem = null;
      this.dialog.open = true;
      this.dialog.type = "create";
    },
    async load() {
      const { data } = await this.axios.get(`/ichibanDelivery`);
      this.data = data.reverse();
    },
    // async getIchiban() {
    //   const { data } = await this.axios.get(`/ichiban`, {
    //     params: { isAvailable: true },
    //   });
    //   this.ichibans = data;
    // },
    async update(item, key) {
      try {
        this.$vloading.show();
        await this.axios.put(`/ichibanDelivery/${item._id}`, {
          [key]: item[key],
        });
      } catch (error) {
        this.$toast.error("建立失敗請洽客服人員");
      }
      this.$vloading.hide();
    },
    async openDialog(item, type) {
      this.selectedItem = item;
      this.dialog.open = true;
      this.dialog.type = type;
    },
  },
  watch: {},
};
</script>

<style scoped lang="scss">
.empty-label {
  position: relative;

  ::v-deep .v-input__slot {
    justify-content: center;
  }
}
.typeWidth {
  width: 140px;
}
</style>
